
import Vue from 'vue';
import { LinkedType } from '../../store/modules/agencyadmin/types';

export default Vue.extend({
  props: ['type', 'user'],
  computed: {
    enabledIcon(): string {
      const type = this.type as LinkedType;
      return type.Enabled ? 'check_circle' : 'error';
    },
    enabledClass(): string {
      const type = this.type as LinkedType;
      return type.Enabled ? 'green--text' : 'red--text';
    },
    typeCheck(): boolean {
      // list of permissions for ads and insights
      const adPerms = ['ads_read', 'business_management'];
      const insightPerms = ['read_insights', 'pages_read_engagement', 'pages_read_user_content'];
      // users missing permissions and type
      const missingPerms = this.user?.MissingPermissions;
      const type = this.type?.Type;
      // check if type is missing a required permission.
      if (missingPerms && type) {
        if (type === 'Ads' && adPerms.some((x: string): boolean => missingPerms.includes(x))) return false;
        if (type === 'Insights' && insightPerms.some((x: string): boolean => missingPerms.includes(x))) return false;
      }
      return true;
    },
  },
});
